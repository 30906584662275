const uniq = (array) => {
  return array
    .map((name) => {
      return {
        count: 1,
        name: name,
      };
    })
    .reduce((a, b) => {
      a[b.name] = (a[b.name] || 0) + b.count;
      return a;
    }, {});
};


const dublicateProp = (adUnits, prop, testers) => {
  let errors = { msg: [], ids: [] };
  let ids = [...adUnits.map((e) => e[prop])];
  const testerIds = testers.map(e => [e.testA.adunitId, e.testB.adunitId]).flat(Infinity)

  let uniqIds = uniq(ids);
  let unique = Object.keys(uniqIds);

  let duplicatesNotInTest = unique.filter((a) => uniqIds[a] > 1).filter(e => {
    const id = adUnits.find(unit => unit[prop] === e).id
    return !testerIds.includes(id)
  });
  if (duplicatesNotInTest.length > 0) {
    errors.ids.push(duplicatesNotInTest.join(","));
    errors.msg.push(
      `Dublicates exist on ${duplicatesNotInTest.join(",")}. Fix this OR create an A/B test!`
    );
  }
  return errors;
};
const toManySiteIds = (adUnits) => {
  let errors = { msg: [], ids: [] };
  adUnits.forEach(unit => {
    if (unit.path.split(',').length > 2) {
      errors.ids.push(unit.divIdOnPage)
      errors.msg.push(`Too many ids in gam path ${unit.divIdOnPage}`)
    }
  })

  return errors;
};

const getAllSizes = sizeSettings => {
  try {
    let allSizesArrays = [...sizeSettings].map((e) => e[1]);
    let allSizes = allSizesArrays
      .flat(1)
      .map((e) => ({ sizeString: JSON.stringify(e) }));
    return [...new Set(allSizes.map((e) => e.sizeString))]
      .map((e) => JSON.parse(e))
      .filter((e) => e.length > 0)
    // .map((e) => ({ value: e, text: e.join("x") }));
  } catch (error) {
    console.log("Could not parse sizes");
  }
}

const addCalculatedValues = arr => arr.map(dateRow => {
  const viewrate = (dateRow.viewables / dateRow.impressions) * 100
  const CTR = (parseInt(dateRow.clicks) / parseInt(dateRow.impressions)) * 100
  const ecpm = (dateRow.revenue / parseInt(dateRow.impressions)) * 1000
  dateRow.ecpm = ecpm
  dateRow.CTR = CTR
  dateRow.viewrate = viewrate
  return dateRow
})


const getArrayDepth = (obj) => {
  if (Array.isArray(obj))
    return 1 + Math.max(...obj.map((t) => getArrayDepth(t)));
  else return 0;
}

const flattenDeep = (arr1) => {
  let depth = getArrayDepth(arr1);
  if (depth === 2) return arr1;
  return arr1
    .reduce(
      (acc, val) =>
        Array.isArray(val)
          ? acc.concat(flattenDeep(val))
          : acc.concat(val),
      []
    )
    .filter(Array.isArray);
}

const makeId = (length) => {
  let result = "";
  let characters = "abcdefghijklmnopqrstuvwxyz";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return result;
}
const byProp = ({ uniqueList, prop, allData, namePropArr = [] }) => {
  try {
    const byProp = uniqueList.map(uniqueVal => {

      const deliveredByProp = allData.filter((e) => e[prop] === uniqueVal);

      if (!deliveredByProp || !deliveredByProp[0]) return {}

      let nameProp = null //prop === 'site' ? namePropArr.find(e => e.Id === uniqueVal).Name : deliveredByProp[0][prop]
      if (prop === 'site') {
        let foundItem = namePropArr.find(e => e.Id === uniqueVal)
        nameProp = foundItem ? foundItem.Name : deliveredByProp[0][prop]
      } else {
        nameProp = deliveredByProp[0][prop]
      }


      const returnObj = { name: nameProp, uniqueVal, impressions: 0, revenue: 0.0, clicks: 0, viewables: 0 };
      deliveredByProp.forEach((datedata) => {
        returnObj.impressions =
          returnObj.impressions + parseInt(datedata['impressions']);
        returnObj.revenue =
          returnObj.revenue + parseFloat(datedata['revenue']);
        returnObj.clicks =
          returnObj.clicks + parseInt(datedata['clicks']);
        returnObj.viewables =
          returnObj.viewables + parseInt(datedata['viewables']);

      });
      return returnObj
    })
    return byProp
  } catch (error) {
    console.log(error);
  }
}


const cleanObject = (obj) => {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj
      .map(item => cleanObject(item))
      .filter(item => item !== null && item !== undefined && !(Array.isArray(item) && item.length === 0) && !(typeof item === 'object' && Object.keys(item).length === 0));
  }

  if (typeof obj === 'object') {
    const newObj = {};
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      const cleanedValue = cleanObject(value);

      if (key === 'size') {
        if (Array.isArray(value) && value.length > 0) {
          newObj[key] = value;
        }
      } else if (cleanedValue !== null && cleanedValue !== undefined &&
        !(Array.isArray(cleanedValue) && cleanedValue.length === 0) &&
        !(typeof cleanedValue === 'object' && Object.keys(cleanedValue).length === 0)) {
        newObj[key] = cleanedValue;
      }
    });
    return newObj;
  }

  return obj;

}
export { byProp, uniq, toManySiteIds, dublicateProp, getAllSizes, flattenDeep, makeId, addCalculatedValues, cleanObject };