import { addCalculatedValues, byProp } from "@/controllers/util";
import { useMainStore } from '@/stores/main'

import { getGamReport, getTracking, getAdpay, getPupOpportunities } from "@/api/background-api";
let utc = require("dayjs/plugin/utc");
let timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
import dayjs from "dayjs";

import { defineStore } from 'pinia'
// const getImpPrLoad = (dates,) => {
//   if (!reportStore.trackingDates) return [];
//   let impPrLoad = reportStore.trackingDates.map((date, index) => {
//     let impressionsForDate = dateDataComputed.value.find((e) => {
//       let itemDateToCheck = dayjs(e.dates[0]).format("YYYY-MM-DD");
//       return itemDateToCheck === date;
//     });

//     if (!impressionsForDate) return null;
//     return (
//       impressionsForDate.impressions[0] / reportStore.trackingNumberRows[index]
//     );
//   });
//   return impPrLoad;
// }
export const useReportStore = defineStore('reporting', {
    state: () => ({
        publishersLoading: false,
        adpayByDate: [],
        adpayByPlacement: [],
        adpayByPublisher: [],
        adpayDates: [],
        orderData: [],
        publisherOpportunities: [],
        adpayRaw: [],
        loadingGam: false,
        dataLoading: false,
        trackingData: [],
        loadingOrders: false,
        trackingLoading: false,
        impressionsPrLoad: []

    }),
    getters: {

        adpayByPublisherSorted(state) {
            return state.adpayByPublisher.sort((a, b) => b.revenue - a.revenue)
        },
        adpayByPlacementSorted(state) {
            return state.adpayByPlacement.sort((a, b) => b.revenue - a.revenue)
        },
        trackingNumberRows(state) {
            let rows = state.trackingData.timeSeriesValue;
            if (!rows) return []
            return rows.map((e) => parseInt(e.value))
        },
        trackingDates(state) {
            let rows = state.trackingData.timeSeriesValue;
            let clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

            if (!rows) return []
            return rows.map((e) =>
                dayjs(e.timestamp)
                    .tz(clientTz)
                    .format("YYYY-MM-DD"))
        },
        trackingsData() {
            const main = useMainStore()

            let rows = this.trackingNumberRows;
            if (!rows) return [];

            return [
                {
                    data: rows,
                    name: "load count",
                    color: main.mainColor,
                    type: "bar", //'bar'


                },
                {
                    data: this.impressionsPrLoad,
                    name: "Imp pr load",
                    type: "line", //'bar'
                    symbolSize: 10,
                    color: '#f54f19',


                },
            ]

        },

    },
    actions: {
        setImpressionsPrLoad(arr) {
            this.impressionsPrLoad = arr

        },
        async LOAD_POPUPP() {
            this.publishersLoading = true

            let allPupOpps = await getPupOpportunities();
            this.publisherOpportunities = allPupOpps

            this.publishersLoading = false
        },
        async LOAD_TRACKING({ name, eventsFromLast, timeBin, eventsFromLastUnit }) {
            this.trackingLoading = true
            this.trackingData = []
            let res = await getTracking({ reportId: 10, name, eventsFromLast, timeBin, eventsFromLastUnit });

            this.trackingData = res




            this.trackingLoading = false
        },

        async LOAD_ADPAY({ publisherOpportunity, start, end }) {
            this.dataLoading = true


            const adpayData = await getAdpay({ publisherOpportunity, start, end });
            this.adpayRaw = adpayData


            if (adpayData !== null) {
                const adpayDataCorrected = adpayData
                const uniquieDates = [...new Set(adpayDataCorrected.map((e) => e["date"])),].sort((a, b) => new Date(a) - new Date(b));
                const uniquieNames = [...new Set(adpayDataCorrected.map((e) => e["Name"])),]
                const uniquieSites = [...new Set(adpayDataCorrected.map((e) => e["site"])),]
                const byDate = byProp({ uniqueList: uniquieDates, prop: 'date', allData: adpayDataCorrected })
                const byPlacment = byProp({ uniqueList: uniquieNames, prop: 'Name', allData: adpayDataCorrected })
                const bySite = byProp({ uniqueList: uniquieSites, prop: 'site', allData: adpayDataCorrected, namePropArr: this.publisherOpportunities })
                const addedEcpmByDate = addCalculatedValues(byDate)
                const addedEcpmByName = addCalculatedValues(byPlacment)
                const addedEcpmBySite = addCalculatedValues(bySite)
                this.adpayByDate = addedEcpmByDate
                this.adpayByPlacement = addedEcpmByName
                this.adpayByPublisher = addedEcpmBySite
                this.adpayDates = uniquieDates


            }
            this.dataLoading = false

        },



        async LOAD_REPORT_4({ site, interval }) {
            try {
                this.loadingOrders = true
                this.orderData = []
                const res = await getGamReport({ reportId: 4, site, interval });
                this.loadingOrders = false
                this.orderData = Object.freeze(res.flat(Infinity))

            } catch (error) {
                this.loadingOrders = false

            }

        }
    }

})