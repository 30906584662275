// import Vue from "vue";
// import Router from "vue-router";
import { useAuth0 } from '@auth0/auth0-vue';
import { useMainStore } from '@/stores/main'

import { createRouter, createWebHistory } from 'vue-router'
// CANNOT LAZYLOAD BEACUSE:
// https://answers.netlify.com/t/keep-js-chunks-from-old-deploys/5991/6
// const SettingsPage = () => import(/* webpackChunkName: "SettingsPage" */"./../views/Settings.vue");
const SettingsPage = () => import(/* webpackChunkName: "SettingsPage" */"./../views/SiteSettings.vue");
// const DeployHistory = () => import(/* webpackChunkName: "DeployHistory" */"./../components/DeployHistory.vue");
// const Implementation = () => import(/* webpackChunkName: "Implementation" */"./../components/Implementation.vue");
// const Adpay = () => import(/* webpackChunkName: "Adpay" */"../views/Adpay.vue");
const adminPanel = () => import(/* webpackChunkName: "AdminPanel" */"./../views/AdminPanel.vue");
const Alerts = () => import(/* webpackChunkName: "Alerts" */"./../views/AlertsComponent.vue");
const Login = () => import(/* webpackChunkName: "Login" */"./../views/Login.vue");
const Home = () => import(/* webpackChunkName: "Home" */"./../views/Home.vue");
const NotFound = () => import(/* webpackChunkName: "NotFound" */"./../views/NotFound.vue");
const Reporting = () => import(/* webpackChunkName: "Reporting" */"./../views/Reporting.vue");
// const Live = () => import(/* webpackChunkName: "Live" */"./../components/Live.vue");

// import SettingsPage from "@/components/Settings.vue";
// import DeployHistory from "@/components/DeployHistory.vue";
// import Implementation from "@/components/Implementation.vue";
// import Statistics from "@/components/Statistics.vue";
// import Home from "@/components/Home.vue";
// import Alerts from "@/components/Alerts.vue";
// import NotFound from "@/components/NotFound.vue";
// import adminPanel from "@/components/AdminPanel.vue";
// import Login from "@/components/Login.vue";
// import Reporting from "@/components/Reporting.vue";


const REQUIREDROUTES = [
  { link: "/", component: Home, meta: { disableTopBar: false, useNavBar: true } },
  { link: "/login", name: 'login', component: Login, meta: { useNavBar: false, disableTopBar: true } },
  { link: "/logout", component: Login, meta: { useNavBar: false, disableTopBar: true } }
]
const CATCHALLMUSTBELASTROUTE = [
  { link: "/*", component: NotFound, meta: { useNavBar: false, disableTopBar: true } }

]
const ADMINROUTES = [
  // {
  //   path: "/Live", 
  //   component: Live, 
  //   meta: { adminOnly: true, useNavBar: true, hideTopBar: true },
  //   sidebarInfo: {
  //     text: "Live",
  //     link: "/live",
  //     icon: "mdi-chart-line-variant",
  //     name: "live",
  //     noId: true,
  //   }
  // },
  // {
  //   path: "/reporting", component: Reporting, meta: { adminOnly: true, useNavBar: true, hideTopBar: true },
  //   sidebarInfo: {
  //     text: "Reporting",
  //     link: "/reporting",
  //     icon: "mdi-chart-box-outline",
  //     name: "reporting",
  //     noId: true,
  //   }
  // },
  {
    path: "/alerts",
    component: Alerts,
    meta: { adminOnly: true, useNavBar: true, disableTopBar: true },
    sidebarInfo: {
      text: "Alerts",
      link: "/alerts",
      icon: "mdi-bell-alert",
      name: "alerts",
      showAlerts: true,
      useAnom: true,
    }
  },
  {
    path: "/admin", component: adminPanel, meta: { adminOnly: true, useNavBar: true, disableTopBar: true }, sidebarInfo: {
      text: "Admin control",
      link: "/admin",
      icon: "mdi-firebase",
      name: "superAdmin",
      noId: true,
      subLinks: [
        { text: "Global", subLinkName: 'globalSettings' },
        { text: "Configurations", subLinkName: 'configurations' },
        { text: "Selector scripts", subLinkName: 'selectorScripts' }
      ],
    }
  },
]

// const SITEDEPEDENT = [

// ]


const OPTIONALROUTES = [


  {
    path: "/settings/:id",
    component: SettingsPage,
    name: 'settings',
    meta: { useNavBar: true },
    sidebarInfo: {
      text: "Site control",
      link: "/settings",
      icon: "mdi-cog-outline",
      name: "Settings",
      subLinks: [
        { text: "General settings", link: '/settings', subLinkName: 'generalSettings' },
        { text: "Adunits", showAdunits: true, subLinkName: 'adunits' },
        { text: "Video units", showVideoUnits: true, hideConceptX: true, subLinkName: 'videoUnits' },
        { text: "A/B testers", icon: "mdi-ab-testing", hideConceptX: true, subLinkName: 'abTesters' },
        { text: "ads.txt management", hideConceptX: true, subLinkName: 'adstxtManagement' },
        { text: "Implementation", hideConceptX: false, subLinkName: 'implementaiton' },
        { text: "Deploy history", hideConceptX: false, subLinkName: 'deployHistory' },
        // { text: "Timings stats", new: true, dependsOnSetting: 'activateTimingTracker', subLinkName: 'siteTiming', hideConceptX: true },
      ],
    }
  },
  // {
  //   path: "/deployHistory/:id", component: DeployHistory, meta: { useNavBar: true }, sidebarInfo: {
  //     text: "Deploy history",
  //     icon: "mdi-sort-clock-ascending-outline",
  //     link: "/deployHistory",
  //     name: "Deploy",
  //   }
  // },
  // {
  //   path: "/tags/:id", component: Implementation, meta: { useNavBar: true }, sidebarInfo: {
  //     text: "Tags",
  //     icon: "mdi-code-tags-check",
  //     link: "/tags",
  //     name: "Tags",
  //   }
  // },
  {
    path: "/reporting", component: Reporting, meta: { hideTopBar: true, useNavBar: true }, sidebarInfo: {
      text: "Reporting",
      icon: "mdi-chart-bar-stacked",
      link: "/reporting",
      noId: true,
      subLinks: [
        { text: "Adpay", subLinkName: 'adpay' },
        { text: "*Xpay", adminOnly: true, subLinkName: 'xPay' },
        { text: "Admin reporting", adminOnly: true, subLinkName: 'adminReport' },
        // { text: "Adpay", subLinkName: 'adpay' },
        // { text: "Site Timings",  subLinkName: 'siteTiming' }
      ],
      name: "Reporting",
    }
  },
  // {
  //   path: "/adpay", component: Adpay, meta: { hideTopBar: true, useNavBar: true }, sidebarInfo: {
  //     text: "Adpay",
  //     icon: "mdi-chart-bar-stacked",
  //     link: "/adpay",
  //     noId: true,
  //     name: "Adpay",
  //   }
  // },
]
// Vue.use(Router);

const router = createRouter({
  // 4. Provide the history implementation to use. We
  // are using the hash history for simplicity here.
  history: createWebHistory(),
  routes: [
    ...REQUIREDROUTES,
    ...OPTIONALROUTES,
    ...ADMINROUTES,
    ...CATCHALLMUSTBELASTROUTE,
  ],
})

// const router = new Router({
//   routes: [
//     ...REQUIREDROUTES,
//     ...OPTIONALROUTES,
//     ...ADMINROUTES,
//     ...CATCHALLMUSTBELASTROUTE,
//   ],
//   mode: "history",
// });
let called = false
function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const readyToProceed = async (isLoading) => {
  if (isLoading.value) {
    await timeout(100)
    if (!isLoading.value) {
      return Promise.resolve(true)
    } else {
      return readyToProceed(isLoading)
    }
  } else { return Promise.resolve(true) }


}

router.beforeEach(async (to, from) => {
  let { isAuthenticated, loginWithRedirect, idTokenClaims, getAccessTokenSilently, isLoading } = useAuth0()
  try {
    const mainStore = useMainStore()

    await readyToProceed(isLoading)
    let authenticated = isAuthenticated._rawValue
    let idTokenClaim = idTokenClaims?._rawValue?.__raw
    let loading = isLoading.value

    if (!loading && !authenticated) {
      // https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/2
      try {
        await getAccessTokenSilently();
      } catch (e) {
        if (e.error === 'login_required') {
          loginWithRedirect();
        }
        if (e.error === 'consent_required') {
          loginWithRedirect();
        }
        console.log('e.error', e.error);
        throw e

      }
    }
    // }





    if (to.meta?.adminOnly) {
      console.log('CHECK FOR ADMIN');
    }







    if (!authenticated && !from.fullPath.startsWith('/?code=') && !to.fullPath.startsWith('/?code=')) {
      console.log('login with redirect');

      loginWithRedirect()
    }

    if (authenticated) {
      mainStore.setIdToken(idTokenClaim)
      if (!called) {
        called = true
        mainStore.APP_INITIALIZATION({ idToken: idTokenClaim })

      }

    }
    // if(!to.path){
    //   to.path = '/'
    // }

    // explicitly return false to cancel the navigation
    return true
  } catch (error) {
    console.log('Router error: ', error);
    loginWithRedirect()


  }
})
export { router, OPTIONALROUTES, ADMINROUTES };
