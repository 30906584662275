import { getEmbeddableToken, getAnomalies, deleteAnomAlert, getAllPupUpp, getAuthGroups, getSuggestions } from "@/api/background-api";
import { set, getAll, del, clear } from "@/cache/idb";
import { setItem, deleteItem } from '@/firebase.js';
import { defineStore } from 'pinia'

export const useAdminStore = defineStore('admin', {
    state: () => ({
        anomalyAlerts: [],
        anomLoading: false,
        xpayToken: null,
        authGroups: [],
        suggestions: [],
        publisherOpportunities: [],
        specialFunctionActions: ['Deploy', 'Refresh', 'Lazyload', 'AllSettings']
    }),
    getters: {
        anomaliesCount: (state) => {
            let count = state.anomalyAlerts.length
            if (count === 0) count = '-'
            return count

        },
        siteSuggestionsForSite: (state) => {
            return (selectedSiteName) => state.suggestions.filter(e => e.site === selectedSiteName)
        },
    },
    actions: {

        async loadSuggestions() {

            try {
                const values = await getAll('suggestions')
                if (Array.isArray(values) && values.length > 0) {
                    this.suggestions = values

                }

                let suggestions = await getSuggestions()
                clear('suggestions')

                suggestions.forEach(anom => {
                    set('suggestions', anom.site, anom)
                })
                this.suggestions = suggestions

            } catch (error) {
                console.log(error);

            }
        },
        async loadAnomalies() {

            try {
                this.anomLoading = true
                const values = await getAll('anomalies')
                if (Array.isArray(values) && values.length > 0) {
                    this.anomalyAlerts = values
                    this.anomLoading = false
                }
                const anomalies = await getAnomalies();
                clear('anomalies')
                anomalies.forEach(anom => {
                    set('anomalies', anom.site, anom)
                })
                this.anomalyAlerts = values

                this.anomLoading = false
            } catch (error) {
                this.anomLoading = false

            }
        },
        async resolveAlert({ site }) {
            let newAnomalies = this.anomalyAlerts.filter((e) => e.site !== site);
            this.anomalyAlerts = newAnomalies
            await Promise.all([deleteAnomAlert(site), del('anomalies', site)])


        },
        async getEmbeddableToken(site, type) {
            try {
                let token = (await getEmbeddableToken({ type, site })).token
                this[`${type}Token`] = token
            } catch (error) {
                console.log(error)
                return error
            }
        },
        async loadPuplisherOpportnities() {
            try {
                const { publisherOpportunities } = await getAllPupUpp();
                this.publisherOpportunities = publisherOpportunities
            } catch (error) {
                console.log(error)
                return error
            }
        },
        async loadGroups() {
            try {
                let groups = (await getAuthGroups()).Items;
                this.authGroups = groups
            } catch (error) {
                console.log(error)
                return error
            }
        },
        async deleteGlobalNotification({ notification }) {
            deleteItem('notifications', notification.id)

        },
        async updateNotification({ notification }) {
            setItem('notifications', notification.id, { ...notification })
        },
        async UPDATE_SITES_OWNER({ site, owner }) {
            setItem('OwnedProducts', site, { site, owner })
        }
    }

})