<template>
  <v-app-bar style="background: #fff" v-if="!activeRoute.meta.hideTopBar">
    <v-app-bar-nav-icon @click="menuDrawer = !menuDrawer" v-if="isMobile"> </v-app-bar-nav-icon>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          You have undeployed changes!
        </v-card-title>
        <v-card-text>If you continue, your current work will be lost</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" variant="text" @click="dialog = false">
            Back to safety
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="clearChangesAndContinue">
            Discard changes and continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">

      <v-spacer></v-spacer>

      <cncpt-select-search :rounded="true" v-model="chosenSite" :items="menu" />




      <v-spacer></v-spacer>

      <v-btn variant="text" color="primary" class="pa-4 mr-6" icon="mdi-account" @click.stop="model = !model"></v-btn>


    </v-row>
    <template v-slot:append>
      <v-btn @click="setSiteBar()" icon="mdi-dots-vertical"></v-btn>
    </template>

  </v-app-bar>

</template>

<script setup>
import { ref, computed, defineModel } from 'vue'
import { router } from "@/router/index.js";


import { useDisplay } from 'vuetify'
import { useUserStore } from '@/stores/user'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
const userStore = useUserStore()

const display = useDisplay()
const model = defineModel()
const menuDrawer = defineModel('menuDrawer')


const isMobile = computed(() => display.mobile.value)

const deployChangesCount = computed(() => mainStore.changesThatRequireDeploy)
const menu = computed(() => userStore.menu.map(e => {
  e.title = e.overwriteName || e.text
  return e
}))
const activeRoute = computed(() => router.currentRoute._value)
let dialog = ref(false)
let tempRedirect = ref(null)

const setSiteBar = () => {
  mainStore.SET_DRAWER(!mainStore.localBandDrawer )
  
}


const clearChangesAndContinue = () => {
  mainStore.RESET_DEPLOYCHANGES()

  dialog.value = false;
  chosenSite.value = tempRedirect.value
}





const chosenSite = computed({
  // getter
  get() {
    return mainStore.selectedSiteName
  },
  // setter
  async set(val) {

    if (deployChangesCount.value > 1) { // It starts at 1, for deep watcher to work
      // event.stopPropagation();
      dialog.value = true;
      tempRedirect.value = val
    } else {

      const foundOverwrite =
        userStore.siteOverwrites.find((site) => site.nameOverwrite === val)?.name ||
        val;

      mainStore.RESET_DEPLOYCHANGES()
      mainStore.changeEnv('prod')
      mainStore.GET_DATA({ site: foundOverwrite, env: "prod" })

      console.log({ activeRoute: router.currentRoute._value });

      if (!activeRoute.fullPath === '/reporting/') {
        router.push({ name: 'settings', params: { id: foundOverwrite } })
      }

    }
  }
})

</script>

<style lang="scss">
@import "@/styles.scss";
</style>
