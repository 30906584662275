<template>
  <!-- <v-card v-if="helperText || showCard" flat :color="model ? themeColor : null" class="ma-2" variant="outlined">
    <v-card-title class="text-left text-h5">
      {{ title }}
    </v-card-title>
    <v-card-subtitle class="text-left text-h6 text-black">
      {{ helperText }} </v-card-subtitle>
    <v-banner class="my-4" :color="themeColor" lines="one">
      <v-banner-text>
        <v-switch :label="helperText ? null : title" :color="themeColor" @update:model-value="incementChanges"
          :disabled="!isAdmin" v-model="model" hide-details inset />
        </v-banner-text>
  
      </v-banner>
      <slot name="append1"></slot>
      <slot name="append2"></slot>
      <slot name="append3"></slot>
  </v-card> -->

  <v-card v-if="helperText || showCard" flat :color="model ? mainStore.themeSubColor : 'grey'" rounded="xl"
    class="text-left" :title="title">

    <template v-slot:title>

      <v-tooltip location="top">
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props" class="mr-4">mdi-information-outline</v-icon>


        </template>
        <span>{{ helperText }}</span>
      </v-tooltip>
      <span> {{ title }}</span>
    </template>

    <v-card-text class="bg-surface-light pt-4">
      <v-switch :label="helperText ? null : title" :color="mainStore.themeSubColor"
        @update:model-value="incementChanges" :disabled="!isAdmin" v-model="model" hide-details inset />

    </v-card-text>
    <!-- hasSlot {{  !!this.$slots.append1 }} -->
    <v-row class="pa-4 bg-grey" v-if="!!$slots.append1">
      <v-col cols="12" md="4">
        <slot name="append1" />
      </v-col>
      <v-col cols="12" md="4">
        <slot name="append2" />
      </v-col>
      <v-col cols="12" md="4">
        <slot name="append3" />
      </v-col>

    </v-row>


  </v-card>

  <v-switch v-else :label="helperText ? null : title" :color="mainStore.themeSubColor"
    @update:model-value="incementChanges" :disabled="!isAdmin" v-model="model" hide-details inset />


</template>


<script setup>
import { defineModel } from 'vue'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
const model = defineModel()


const incementChanges = () => {
  mainStore.INCREMENT_DEPLOY_CHANGES()
}
defineProps({
  title: { Type: String },
  text: { Type: String, default: "" },
  isAdmin: { Type: Boolean },
  helperText: { Type: String, default: "" },
  showCard: { Type: Boolean, default: false },
})


</script>


<style lang="scss" scoped>
@import "@/styles.scss";
</style>
