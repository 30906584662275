const { openDB } = require('idb');


const dbPromise = openDB('central', 1, {
    upgrade(db) {
        db.createObjectStore('sizes');
        db.createObjectStore('anomalies');
        db.createObjectStore('suggestions');


    }
});



async function get(storeName, key) {
    return (await dbPromise).get(storeName, key);
}
async function set(storeName, key, val) {
    return (await dbPromise).put(storeName, val, key);
}
async function del(storeName, key) {
    return (await dbPromise).delete(storeName, key);
}
async function clear(storeName) {
    return (await dbPromise).clear(storeName);
}
async function keys(storeName) {
    return (await dbPromise).getAllKeys(storeName);
}
async function getAll(storeName) {
    return (await dbPromise).getAll(storeName);


}

module.exports = { get, set, del, clear, keys, getAll }