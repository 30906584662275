<template>
  <v-container>
    <cncpt-text-field :disabled="true" :label="userStore.userEmail"></cncpt-text-field>
    <v-expansion-panels>
      <!-- <v-expansion-panel>
        <v-expansion-panel-title>
          <v-row align="center" class="spacer" no-gutters>
            <v-col sm="12" md="12">
              <strong>Color preference</strong>
              <span class="text-grey"> </span>
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-divider></v-divider>
          <v-row class="pa-4">
            <v-list-subheader>Main color</v-list-subheader>
            <v-color-picker v-model="localUserSettings.topbarColor.hex" class="ma-2" mode="hexa"
              show-swatches></v-color-picker>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel> -->
      <v-expansion-panel hide-actions>
        <v-expansion-panel-title>
          <v-row align="center" class="spacer" no-gutters>
            <v-col sm="12" md="12">
              <strong>API KEY</strong>
              <span class="text-grey"> </span>
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-divider></v-divider>
          <v-row class="pa-4">
            <cncpt-text-field v-model="localUserSettings.apikey" class="ma-2" :append-icon="localUserSettings.apikey.length === 0
              ? 'mdi-key-plus'
              : 'mdi-refresh'
              " @click:append="createNewApiKey" readonly></cncpt-text-field>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>

    </v-expansion-panels>
    <v-btn @click="postChange" class="mt-4" variant="outlined" :color="mainStore.themeColor">Save
      preferences</v-btn>
  </v-container>
</template>
<script setup>
import { postUserSettings } from '@/api/background-api';
import { watchEffect, ref, toRaw } from 'vue'
import { useUserStore } from '@/stores/user'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
const userStore = useUserStore()

import { useHelpers } from '@/composables/helpers.js'
const { makeId } = useHelpers()
let localUserSettings = ref({})
watchEffect(() => {
  localUserSettings.value = userStore.userSettings
})




const createNewApiKey = async () => {
  userStore.setUser({ ...localUserSettings.value, data: { userSettings: { apikey: makeId(26) } } })
  await postChange();
}
const postChange = async () => {

  await postUserSettings({
    settings: { email: userStore.userEmail, newSettings: toRaw(localUserSettings.value) },
  });
}


</script>

<style lang="scss">
@import '@/styles.scss';
</style>
