import { defineStore } from 'pinia'
import { ADMINROUTES, OPTIONALROUTES } from "@/router/index.js";

export const useUserStore = defineStore('user', {
    state: () => ({
        user: { loggedIn: false },
        userSettings: {
            topbarColor: { hex: '#405AE5FF' },
            apikey: ''
        },
        sidebarItems: [{
            text: "Home", noId: true, link: "/", icon: "mdi-home", name: "Home",
            // subLinks: [
            //   { text: "Prebid overview" },
            // ]
        }],
        menu: [],
        siteOverwrites: [],
        isAdmin: false,
        allowedSites: [],
        subMenuName: ''

    }),
    getters: {

        userEmail(state) {
            if (!state.user) return null
            return state.user.email;
        },
        sidebarItemsFiltered(state) {
            let blackedPages = state?.user?.data?.blockPages
            if (blackedPages) {

                return state.sidebarItems.filter(site => {
                    return !blackedPages.includes(site.link)
                })
            } else {

                return state.sidebarItems
            }
        },
        allowedToDeploy(state) {
            let hasSpecialAccess = state.user?.specificAccess ? state.user?.specificAccess.includes('Deploy') : false
            return state.isAdmin || hasSpecialAccess
        },
        allowedToChangeLazy(state) {
            return state.isAdmin || state.user?.specificAccess?.includes('Lazyload') || this.allowedAdmin
        },
        allowedToChangeRefresh(state) {
            return state.isAdmin || state.user?.specificAccess?.includes('Refresh') || this.allowedAdmin
        },
        allowedAdmin(state) {
            return state.isAdmin || state.user?.specificAccess?.includes('AllSettings')
        },

    },
    actions: {
        changeSubMenu({ newSubMenuName }) {
            this.subMenuName = newSubMenuName;
        },
        setUser({ auth }) {
            let userData = auth.user;
            let allowedSites = userData.siteAccess;
            let siteOverwrites = userData.siteOverwrites;

            this.allowedSites = allowedSites
            this.siteOverwrites = siteOverwrites



            this.menu = allowedSites.map((e) => {
                let overwriteName = siteOverwrites.find(site => site.name === e)
                return {
                    ...(overwriteName && { overwriteName: overwriteName.nameOverwrite }),

                    text: e.toLowerCase(),
                    link: `?site=${e}`,
                };
            });



            const isAdmin = userData.role === "admin";
            if (isAdmin) {
                let adminSidebarItems = [...OPTIONALROUTES, ...ADMINROUTES].map(
                    (item) => item.sidebarInfo
                );
                adminSidebarItems.forEach((sidebarItem) => {
                    this.sidebarItems.push(sidebarItem);
                });

            } else {
                let adminSidebarItems = [...OPTIONALROUTES].map(
                    (item) => item.sidebarInfo
                );
                adminSidebarItems.forEach((sidebarItem) => {
                    this.sidebarItems.push(sidebarItem);
                });
            }

            this.isAdmin = isAdmin;
            this.userSettings = { ...this.userSettings, ...userData.userSettings }
            this.user = userData;

        },
        async LOG_OUT() {
            this.allowedSites = [];
            this.siteOverwrites = [];
            this.menu = [];
            // state.sites = [];
            this.isAdmin = false;
            this.user = {};

        },

    }
})