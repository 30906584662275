<template>
  <v-banner lines="one" density="compact">
    <v-banner-text :style="{ 'font-size': '18px', width: textWidthPercent + '%', 'text-align': 'left' }">
      <v-tooltip location="top" v-if="!hideTooltip">
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props" class="mr-4">mdi-information-outline</v-icon>


        </template>
        <span>{{ helperText }}</span>
      </v-tooltip>
      {{ title }}

    </v-banner-text>

    <v-combobox class="py-2" :menuProps="{ location: 'left' }" :disabled="!isAdmin" variant="solo-filled"
      v-model="model" :clearable="true" hide-details="auto" :items="items" @update:modelValue="incementChanges"
      :item-title="itemTitle" :return-object="false" :item-value="itemValue" :multiple="allowMultiple" :label="title" />

    <!-- <template v-slot:actions style="width: 100%;"> -->


    <!-- <cncpt-basic-select  :disabled="!isAdmin" :multiple="allowMultiple" :label="label" :color="model ? mainStore.themeSubColor : null"
       @change="incementChanges" :itemName="itemTitle" :itemValue="itemValue" v-model="model" :items="items" /> -->


    <!-- </template> -->
  </v-banner>



</template>


<script setup>
import { defineModel } from 'vue'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
const model = defineModel()


const incementChanges = () => {
  mainStore.INCREMENT_DEPLOY_CHANGES()
}
defineProps({
  title: { Type: String },
  isAdmin: { Type: Boolean },
  helperText: { Type: String, default: "" },
  itemTitle: {
    type: String,
    default: 'title'
  },
  itemValue: {
    type: String,
    default: 'value'
  },
  label: {
    default: ''
  },
  items: { Type: Array },
  allowMultiple: { Type: Boolean, default: false },
  hideTooltip: { Type: Boolean, default: false },
  textWidthPercent: { Type: Number, default: 50 },

})


</script>


<style lang="scss">
@import "@/styles.scss";
</style>
