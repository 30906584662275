<template>
  <v-banner lines="one" density="compact">
    <v-banner-text style="font-size: 18px;">
      <v-tooltip location="top" v-if="!hideTooltip">
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props" class="mr-4">mdi-information-outline</v-icon>


        </template>
        <span>{{ helperText }}</span>
      </v-tooltip>
      {{ title }}
    </v-banner-text>

    <template v-slot:actions>
      <v-switch :color="mainStore.themeSubColor" @update:model-value="incementChanges" :disabled="!isAdmin"
        v-model="model" hide-details inset />
    </template>
  </v-banner>



</template>


<script setup>
import { defineModel } from 'vue'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
const model = defineModel()


const incementChanges = () => {
  mainStore.INCREMENT_DEPLOY_CHANGES()
}
defineProps({
  title: { Type: String },
  isAdmin: { Type: Boolean },
  helperText: { Type: String, default: "" },
  hideTooltip: { Type: Boolean, default: false },

})


</script>


<style lang="scss" scoped>
@import "@/styles.scss";
</style>
